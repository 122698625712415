{
  "name": "portal-ui",
  "version": "3.4.9",
  "scripts": {
    "analyze": "node --max_old_space_size=12000 ./node_modules/@angular/cli/bin/ng.js build -c development --stats-json && webpack-bundle-analyzer ./dist/client/stats.json",
    "analyze:prod": "node --max_old_space_size=12000 ./node_modules/@angular/cli/bin/ng.js build --stats-json --named-chunks && webpack-bundle-analyzer ./dist/client/stats.json",
    "build:dev": "node --max_old_space_size=12000 ./node_modules/@angular/cli/bin/ng.js build -c development",
    "build": "node --max_old_space_size=12000 ./node_modules/@angular/cli/bin/ng.js build --base-href=/",
    "compress": "grunt",
    "dev": "node --max-old-space-size=12000 ./node_modules/@angular/cli/bin/ng.js serve --port 4200 --ssl --proxy-config ./proxy.config.js",
    "start": "node --max_old_space_size=12000 ./node_modules/@angular/cli/bin/ng.js serve --port 4200 --ssl --proxy-config ./proxy.config.js",
    "depcruise": "npx depcruise --config .dependency-cruiser.js src",
    "checkdeps:gui": "npx depcruise src --include-only \"^src\" --output-type dot | dot -T svg > dependency-graph.svg"
  },
  "private": true,
  "dependencies": {
    "@amcharts/amcharts4": "^4.10.36",
    "@angular/animations": "^17.3.10",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.10",
    "@angular/compiler": "^17.3.10",
    "@angular/core": "^17.3.10",
    "@angular/forms": "^17.3.10",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "^17.3.10",
    "@angular/platform-browser-dynamic": "^17.3.10",
    "@angular/service-worker": "^17.3.10",
    "@dagrejs/dagre": "^1.1.2",
    "@dotglitch/ngx-common": "^1.1.46",
    "@dt-esa/dynatrace-api-client": "^2.3.6",
    "@dynatrace/dtrum-api-types": "^1.283.3",
    "@egjs/flicking": "^4.11.2",
    "@egjs/flicking-plugins": "^4.7.1",
    "@egjs/ngx-flicking": "^4.11.2",
    "@fontsource/material-icons-outlined": "^5.0.12",
    "@fontsource/poppins": "^4.5.5",
    "@interactjs/actions": "^1.10.26",
    "@interactjs/auto-start": "^1.10.26",
    "@interactjs/core": "^1.10.26",
    "@interactjs/interact": "^1.10.26",
    "@interactjs/modifiers": "^1.10.26",
    "@syncfusion/ej2": "25.1.40",
    "@syncfusion/ej2-angular-buttons": "25.1.39",
    "@syncfusion/ej2-angular-calendars": "25.1.40",
    "@syncfusion/ej2-angular-charts": "25.1.41",
    "@syncfusion/ej2-angular-dropdowns": "25.1.41",
    "@syncfusion/ej2-angular-grids": "25.1.41",
    "@syncfusion/ej2-angular-inputs": "25.1.40",
    "@syncfusion/ej2-angular-layouts": "25.1.37",
    "@syncfusion/ej2-angular-lists": "25.1.41",
    "@syncfusion/ej2-angular-maps": "25.1.41",
    "@syncfusion/ej2-angular-navigations": "25.1.41",
    "@syncfusion/ej2-angular-popups": "25.1.41",
    "@syncfusion/ej2-angular-querybuilder": "25.1.40",
    "@syncfusion/ej2-angular-schedule": "25.1.41",
    "@syncfusion/ej2-angular-splitbuttons": "25.1.38",
    "@syncfusion/ej2-angular-spreadsheet": "25.1.41",
    "@syncfusion/ej2-base": "25.1.35",
    "@syncfusion/ej2-data": "25.1.35",
    "@syncfusion/ej2-icons": "25.1.35",
    "3d-force-graph": "^1.73.2",
    "angular-split": "^17.2.0",
    "d3": "^7.9.0",
    "d3-hierarchy": "^3.1.2",
    "deep-diff": "^1.0.2",
    "dompurify": "^3.1.5",
    "echarts": "^5.5.0",
    "formiojs": "^4.14.6",
    "globe.gl": "^2.32.4",
    "marked": "^4.2.12",
    "monaco-editor": "^0.48.0",
    "ngx-echarts": "^17.1.0",
    "ngx-pipes": "^3.2.2",
    "ngx-stackedit": "^1.1.68",
    "path-browserify": "^1.0.1",
    "powerbi-client": "^2.22.4",
    "powerbi-models": "^1.14.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "reactflow": "^11.10.4",
    "rxjs": "~7.8.1",
    "stats.js": "^0.17.0",
    "tslib": "^2.6.2",
    "typescript": "~5.3.3",
    "ulidx": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.10",
    "@types/dompurify": "^3.0.5",
    "@types/lodash": "^4.14.167",
    "@types/marked": "^4.0.8",
    "@types/react": "^18.2.48",
    "@types/react-dom": "^18.2.18",
    "@types/tabulator-tables": "^6.2.0",
    "cytoscape": "^3.29.2",
    "dependency-cruiser": "^15.5.0",
    "diff-match-patch": "^1.0.5",
    "express-http-proxy": "^2.0.0",
    "filerobot-image-editor": "^4.7.0",
    "localforage": "^1.10.0",
    "markdown-it": "^13.0.1",
    "markdown-it-abbr": "^1.0.4",
    "markdown-it-deflist": "^2.1.0",
    "markdown-it-emoji": "^2.0.2",
    "markdown-it-footnote": "^3.0.3",
    "markdown-it-imsize": "^2.0.1",
    "markdown-it-mark": "^3.0.1",
    "markdown-it-pandoc-renderer": "1.2.0",
    "markdown-it-sub": "^1.0.0",
    "markdown-it-sup": "^1.0.0",
    "mermaid": "^9.4.3",
    "ngx-scrollbar": "13.0.3",
    "prismjs": "^1.29.0",
    "react-filerobot-image-editor": "^4.7.0",
    "tabulator-tables": "^6.2.1",
    "tcp-port-used": "^1.0.2",
    "turndown": "^7.1.2"
  },
  "browser": {
    "path": "path-browserify"
  }
}
